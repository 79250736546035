import { FunctionComponent, memo } from 'react';
import { Helmet } from 'react-helmet-async';

import { E_PREVIEW_THEME } from '@common/enums';
import { useThemeStore } from '@common/stores';

import { headConfig } from './configs';
import type { THelmetHeadProps } from './types';

const HelmetHead: FunctionComponent<THelmetHeadProps> = (props) => {
  const { title, forceTheme } = props;

  const { theme, isNeutralApplicable } = useThemeStore();
  const fallbackTheme = forceTheme ? E_PREVIEW_THEME.YOC : null;
  const appliedTheme = isNeutralApplicable ? theme || fallbackTheme : E_PREVIEW_THEME.YOC;

  return (
    <Helmet title={title ?? (appliedTheme ? headConfig[appliedTheme].title : headConfig.default.title)}>
      {appliedTheme ? headConfig[appliedTheme].head : null}
    </Helmet>
  );
};

export default memo(HelmetHead);
