import { create } from 'zustand';

import { E_PREVIEW_THEME, TTheme } from '@common/enums';
import { fetchAdData } from '@common/helpers';
import type { TAdDataBase, TSlotId } from '@common/types';

// ADS STORE
export type TUseAdsStore = {
  adsData: TAdDataBase | undefined;
  loading: boolean;
  error: unknown | null;
  fetchAds: (slotIds: TSlotId[]) => void;
  setAdsData: (adsData: TUseAdsStore['adsData']) => void;
};
export const useAdsStore = create<TUseAdsStore>((set, get) => {
  return {
    adsData: undefined,
    loading: false,
    error: null,
    fetchAds: async (slotIds) => {
      if (!get().loading) {
        // let slotIdList = slotIds;
        // // fetchAds is used to fetch all the ads and also given an array of slotids
        // // if slotIds is an array with one item that is /{slotid}
        // // or /{slotId}+{slotId} and if slotid containing text doesnt have
        // // a valid(regex) slot id, don't fetch.
        // if (slotIds.length === 1){
        //   if (!isSlotIdUrlValid(slotIds[0])) {
        //     return;
        //   }
        //   slotIdList = splitSlotIdsFromUrl(slotIds[0]);
        // }

        set({ loading: true });
        try {
          const adsData = await fetchAdData(slotIds);

          set({ adsData });
        } catch (error: unknown) {
          set({ error });
        } finally {
          set({ loading: false });
        }
      }
    },
    setAdsData: (adsData: TUseAdsStore['adsData']) => {
      set({ adsData });
    },
  };
});

// PREVIEW STORE
// export type TUsePreviewStore = {
//   previewMode: E_PREVIEW_MODE;
//   isRestrictedShowcase: boolean;
//   setIsRestrictedShowcase: (isRestrictShowcase: TUsePreviewStore['isRestrictedShowcase']) => void;
//   setPreviewMode: (previewMode: TUsePreviewStore['previewMode']) => void;
// };
// export const usePreviewStore = create<TUsePreviewStore>((set) => {
//   return {
//     previewMode: E_PREVIEW_MODE.MOBILE,
//     isRestrictedShowcase: false,
//     setPreviewMode: (previewMode: TUsePreviewStore['previewMode']) => {
//       set({ previewMode });
//     },
//     setIsRestrictedShowcase: (isRestrictedShowcase: TUsePreviewStore['isRestrictedShowcase']) => {
//       set({ isRestrictedShowcase });
//     },
//   };
// });

// THEME STORE
export type TUseTheme = {
  theme: TTheme | null;
  // Added for loading neutral cmp
  // Neutral CMP is loaded only if the domain is advertising.gallery
  isNeutralApplicable: boolean;
};
export const useThemeStore = create<TUseTheme>(() => {
  return {
    theme: null,
    isNeutralApplicable: false,
  };
});

export const setTheme = (theme: TTheme) => {
  useThemeStore.setState({ theme });
};

export const setIsNeutralApplicable = (isNeutralApplicable: boolean) => {
  useThemeStore.setState({ isNeutralApplicable });
};

export const themeController = (theme: TTheme, force: boolean = false) => {
  const { isNeutralApplicable, theme: currentTheme } = useThemeStore.getState();

  // we're allowed to set only Neutral theme in case if Creative has `previewWebsiteLayout` === `neutral`
  if (force || (currentTheme !== theme && theme === E_PREVIEW_THEME.NEUTRAL && isNeutralApplicable)) {
    setTheme(theme);
  }
};

// GALLERY STORE
export type TUseGallery = {
  activeSlideNumber: number;
};
export const useGalleryStore = create<TUseGallery>(() => {
  return {
    activeSlideNumber: 0,
  };
});
export const setActiveSlideNumber = (activeSlideNumber: number) => {
  useGalleryStore.setState({ activeSlideNumber });
};

// MODAL STORE
export type TUseModal = {
  isModalOpen: boolean;
  isTurnModal: boolean;
  modalSlotId: string | undefined;
};
export const useModalStore = create<TUseModal>(() => {
  return {
    isModalOpen: false,
    isTurnModal: false,
    modalSlotId: undefined,
  };
});
export const setIsModalOpen = (isModalOpen: boolean) => {
  useModalStore.setState({ isModalOpen });
};
export const setIsTurnModal = (isTurnModal: boolean) => {
  useModalStore.setState({ isTurnModal });
};
export const setModalSlotId = (modalSlotId: TSlotId) => {
  useModalStore.setState({ modalSlotId });
};
