import { FunctionComponent } from 'react';

import Page from '@components/Page';

import { MESSAGE_NO_MATCH } from './constants';
import type { TNoMatchPageProps } from './types';

const NoMatchPage: FunctionComponent<TNoMatchPageProps> = (props) => {
  const { message = MESSAGE_NO_MATCH } = props;

  return (
    <Page forceTheme>
      <div
        className="main"
        data-testid="no-match-page"
      >
        <h1>PAGE NOT FOUND</h1>
        <p>{message}</p>
      </div>
    </Page>
  );
};

export default NoMatchPage;
