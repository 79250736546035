import { E_PREVIEW_THEME } from '@common/enums';

import { getTcfAPIHandlerCode } from './helpers';

const defaultConfig = {
  title: 'YOC Ads Showcase Platform',
  head: (
    <>
      {process.env.NODE_ENV !== 'development' && (
        <>
          <script
            type="text/javascript"
            src="https://cdn.consentmanager.net/delivery/autoblocking/3fac20376a71.js"
            data-cmp-ab="1"
            data-cmp-host="c.delivery.consentmanager.net"
            data-cmp-cdn="cdn.consentmanager.net"
            data-cmp-codesrc="1"
          />
          <script>{getTcfAPIHandlerCode()}</script>
          <script>window.cmp_block_unknown = false;</script>
        </>
      )}
      <link rel="apple-touch-icon" href="/apple-touch-icon.png" />
      <link rel="mask-icon" href="/favicon.svg" color="#1d223e" />
      <link rel="shortcut" type="image/x-icon" href="/favicon.ico" sizes="any" />
      <link rel="icon" type="image/svg+xml" href="/favicon.svg" />
    </>
  ),
};

export const headConfig = {
  [E_PREVIEW_THEME.YOC]: { ...defaultConfig },
  [E_PREVIEW_THEME.NEUTRAL]: {
    title: 'Ads Showcase Platform',
    head: (
      <>
        {process.env.NODE_ENV !== 'development' && (
          <script
            type="text/javascript"
            async
            src={process.env.PUBLIC_URL + '/cmp-inmobi.js'}
          />
        )}
        <link
          rel="icon"
          type="image/x-icon"
          href="data:image/x-icon;base64,AAABAAEAEBAQAAAAAAAoAQAAFgAAACgAAAAQAAAAIAAAAAEABAAAAAAAgAAAAAAAAAAAAAAAEAAAAAAAAAAAAAAAsC8qAP+EAACzh1cAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAAAAAAAAACAAAAAAAAACAAAAAAAAEiAAAAADAAAiAAAAAAMzAiAAAAAAAAMzAAAAAAAAAiMzMAAAAAAAADAzAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAD//wAA//8AAP//AAD//wAA//8AAP//AAD//wAA//8AAP//AAD//wAA//8AAP//AAD//wAA//8AAP//AAD//wAA" // eslint-disable-line
        />
      </>
    ),
  },
};
